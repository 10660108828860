import { tw } from '@/utils/tw';

const Adunit = tw.theme({
  slots: {
    base: [
      'ad-row-column',
      'relative',
      'z-10',
      'min-h-[388px]',
      'w-full',
      'bg-gray-200',
      'text-center',
      'font-sans',
      'text-xs',
      'leading-6.5',
      'wings',
      'wings-gray-200',
      'col-span-12',
      'group-[]/article:my-6',
      'sm:min-h-[326px]',
      'md:p-0',
    ],
    headline: [],
    wrapper: ['box-content', 'flex', 'items-start', 'justify-center', 'overflow-visible', 'pb-3'],
    placement: ['flex', 'h-full', 'w-full', 'items-start', 'justify-center', 'md:p-0'],
  },
});

export default Adunit;
