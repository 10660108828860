import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';

const Image = tw.theme({
  extend: ImageTheme,
  base: ['relative', 'block', 'aspect-default', 'object-cover'],
});

const ArticleTeaserDefault = tw.theme({
  slots: {
    base: ['grid-container', 'grid', 'w-full'],
    caption: ['break-words', 'capitalize', 'text-primary-700', 'text-headline-xs'],
    description: ['text-body-xs', 'break-words'],
    footer: ['max-w-full', 'overflow-hidden'],
    group: ['flex', 'min-w-[0]', 'flex-col', 'gap-0.5'],
    header: ['max-w-full', 'overflow-hidden'],
    headline: ['text-headline-sm', 'break-words'],
  },
  variants: {
    variant: {
      horizontal: {
        base: ['grid-cols-2', 'gap-x-grid'],
        caption: [],
        description: ['md:grid-cols-7:mt-3', 'md:grid-cols-7:text-body-md'],
        footer: ['col-span-full'],
        group: [],
        header: ['col-span-2'],
        headline: ['md:grid-cols-7:text-headline-lg'],
      },
      vertical: {
        base: ['auto-rows-min', 'gap-4'],
        caption: [],
        description: [
          'mt-1',
          'grid-cols-7:text-body-md',

          'md:grid-cols-4:mt-2',
          'md:grid-cols-7:mt-3',
          'md:text-body-md',
        ],
        footer: [],
        group: [],
        header: [],
        headline: [
          'grid-cols-7:text-headline-md',

          'sm:text-headline-md',

          'md:grid-cols-4:text-headline-lg',
          'md:grid-cols-7:text-headline-xl',
        ],
      },
    },
  },
  defaultVariants: {
    variant: 'vertical',
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
