import { ButtonTheme } from '@/components/Button/theme';
import { tw } from '@/utils/tw';

const Button = ButtonTheme;

const Row = tw.theme({
  slots: {
    base: [
      'group/row',
      'row',
      'relative',
      'mx-auto',
      'grid',
      'w-full',
      'max-w-grid',
      'auto-rows-min',
      'grid-cols-12',
      'gap-grid',
      'empty:hidden',
      'group-[]/row:flex',
      'group-[]/row:flex-col',
      '[&:not(:has(.box))]:flex',
      '[&:not(:has(.box))]:flex-col',
      '[&:not(:has(:not(.box:empty)))]:hidden',
      '[&.bg]:py-grid-gap',
    ],
    headline: [
      'col-span-12',
      'w-full',
      'text-black',
      'text-headline-sm',
      'after:h-0.25',
      'after:bg-black',
      'sm:text-headline-md',
    ],
    caption: ['col-span-12', 'w-full', 'text-black', 'text-body-sm'],
    footer: ['col-span-12', 'flex', 'w-full', 'items-center', 'justify-center', '[&:not(:first-child)]:mt-12'],
  },
});

export default Object.assign(Row, { Button });
