import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const Image = tw.theme({
  extend: ImageTheme,
  base: ['aspect-default'],
});

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    caption: [
      'uppercase',
      'text-logga-700',
      'text-category-md',
      'group-[.bg-al-logga-800]/row:text-white',
      'group-[.bg-black]/row:text-white',
    ],
    description: ['text-body-md', 'group-[.bg-al-logga-800]/row:text-white', 'group-[.bg-black]/row:text-white'],
    headline: ['group-[.bg-al-logga-800]/row:text-white', 'group-[.bg-black]/row:text-white'],
  },
  variants: {
    variant: {
      horizontal: {
        description: ['sm:grid-cols-7:mt-3', 'lg:grid-cols-7:text-body-lg'],
        headline: ['text-headline-xs', 'sm:grid-cols-7:text-headline-xs', 'md:text-headline-md'],
      },
      vertical: {
        base: ['w-full', 'auto-rows-min', 'gap-3', 'sm:grid-cols-4:mb-5'],
        description: ['mt-1', 'lg:grid-cols-7:text-body-lg'],
        headline: [
          'text-headline-sm',
          'grid-cols-12:text-headline-sm',

          'sm:text-headline-sm',
          'sm:grid-cols-4:text-headline-sm',
          'sm:grid-cols-7:text-headline-md',

          'md:grid-cols-7:text-headline-lg',

          'lg:grid-cols-7:text-headline-lg',
        ],
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
